.projects-list-container {
  margin-top: 20px;
}
.projects-list {
  list-style: none;
  padding: 0;
}
.projects-list-title {
  border-radius: 5px 5px 0 0;
  margin-top: 10px;
  margin-bottom: 20px;
  text-align: center;
  background-color: rgba(255, 255, 255, 0.8);
  max-width: 450px;
  margin: 0 auto;
}
.projects-list-title h4,
h6 {
  font-family: "russo one";
}
