.contact-me-container {
  display: grid;
  justify-content: center;
  align-items: center;
  margin: 1rem 0;
  margin-top: 3rem;
}
.contact-me-title {
  padding: 0.5rem 6rem;
  border-radius: 5px 5px 0 0;
  margin-top: 10px;
  margin-bottom: 20px;
  text-align: center;
  background-color: rgba(255, 255, 255, 0.8);
  max-width: 450px;
  margin: 0 auto;
}
.email-container {
  background-color: rgba(255, 255, 255, 0.8);
  font-size: 1.2rem;
  text-align: center;
  padding: 0.4rem 0;
}
.email-container a {
  text-decoration: none;
  color: black;
  font-family: "Courier New", Courier, monospace;
}
.icon-link-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem 0;
  background-color: rgba(255, 255, 255, 0.8);
  border-radius: 0 0 5px 5px;
}
.icon-link-container i {
  font-size: 2rem;
  padding: 0 1rem;
  color: black;
}
