.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
#game-canvas {
  border: 2px solid;
}
.background-animation {
  position: fixed;
  z-index: -1;
}
