body {
  margin: 0;
}
:root {
  --blue: #0966c2;
  --maroon: rgb(211, 15, 48);
  --orange: #ff4500;
  --yellow: #f0c808;
  --dark-purple: #1b1523;
  --skyblue: #c5e5ed;
  --pink: #c23866;
}
h1 {
  font-size: 56px;
}
h2 {
  font-size: 36px;
}
h3 {
  font-size: 28px;
}
h4 {
  font-size: 24px;
}
h5 {
  font-size: 20px;
}
h6 {
  font-size: 16px;
}
h1,
h2,
h3,
h4,
h5,
h6,
strong {
  color: var(--mainText);
  font-family: "Russo One", sans-serif;
  font-weight: 500;
}
