.project-Wrapper {
  color: white;
  display: grid;
  justify-content: center;
  text-align: center;
  user-select: none;
  margin-top: 28px;
  margin-bottom: 50px;
}
.project-name {
  font-family: "Russo one", sans-serif;
  font-size: 1.9rem;
  width: 100%;
  background-color: var(--maroon);
  border-radius: 5px 5px 0px 0px;

  /* background-color: #ff4500; */
}
.project-image {
  width: 350px;
}
.project-image img {
  display: block;
  width: 100%;
  height: 400px;
  object-fit: cover;
}
.project-play-button {
  width: 100%;
  background-color: var(--blue);
  border-radius: 0px 0px 5px 5px;
}
#play-button {
  padding-top: 0.22rem;
  padding-bottom: 0.22rem;
  background-color: white;
  width: 40%;
  margin: 0 auto;
}
.project-play-button a {
  cursor: pointer;
  padding-left: 2.5rem;
  padding-right: 2.5rem;
  font-size: 1.5rem;
  font-family: "Russo one", sans-serif;
  color: black;
  user-select: none;
  text-decoration: none;
}
