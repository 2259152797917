.about {
  justify-self: center;
  background-color: rgba(255, 255, 255, 0.8);
  border-radius: 5px;
}
.about p {
  font-size: 1.25rem;
  color: black;
  font-weight: 600;
  font-family: "Raleway", sans-serif;
  line-height: 1.5rem;
  width: 400px;
  margin-top: 1rem;
  margin-bottom: 1rem;
  word-spacing: 0.2rem;
}
.about p i {
  font-size: 1.6rem;
  margin-right: 0.5rem;
  margin-left: 0.5rem;
}
@media screen and (max-width: 420px) {
  .about p {
    font-size: 1.2rem;
    width: 330px;
  }
}
