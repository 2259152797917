*,
*::after,
*::before {
  /* border: 1px solid white; */
  margin: 0;
}

@import url("https://fonts.googleapis.com/css2?family=Bangers&family=Roboto+Mono:wght@500&family=Roboto:wght@500&family=Russo+One&family=Varta&display=swap");

.portfolio-intro {
  user-select: none;
  /* margin-top: 2rem; */
  padding-top: 2.5rem;
  padding-bottom: 2rem;
  text-align: center;
  display: grid;
  justify-content: center;
  width: 400px;
  /* height: 400px; */
  margin: 0 auto;
  /* align-items: center; */
}

.image-container {
  margin: 0 auto;
  border-radius: 50%;
  object-fit: contain;
}
.image-container-div {
  padding: 2rem 0;
}
.my-image {
  display: block;
  margin: 0 auto;
  height: 200px;
  width: 200px;
  object-fit: cover;

  border-radius: 50%;
}
.title h2,
.title h4 {
  /* font-family: "Varta", sans-serif; */

  background-image: linear-gradient(225deg, #ff6c28, #fbff03fa);
  filter: drop-shadow(0 20px 30px #28d8ff33);
  color: black;
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  -webkit-box-decoration-break: clone;
  /* color: white; */
  margin-bottom: 1rem;
  box-shadow: -1px 1px 3px -1px rgba(0, 0, 0, 0.75);
}
.title h2 {
  margin-top: 1rem;
  font-family: "Russo one", sans-serif;
}
.lottie-anims {
  width: 150px;
  height: 150px;
  cursor: pointer;
}
